<template>
  <div class="admin-form-forms" :class="{skeleton: !state.applications.loaded}">
    <div class="top">
      <div class="left">
      <span class="total font-sm">
        <span>검색 결과: </span>
        <b>{{ state.applications.total }}건</b>
        </span>
        <div class="conditions" v-if="computedConditions.length">
          <div class="condition" :title="c.title" v-for="(c, idx) in computedConditions" :key="idx">
            <span class="text">{{ c.text }}</span>
            <span class="remove" @click="remove(c.name)" title="삭제">&times;</span>
          </div>
        </div>
      </div>
      <div class="right">
        <label class="search" :for="`${component.name}Search`">
          <input type="text" class="form-control form-sm" v-model="state.conditions.keyword" @keyup.enter="toggleCondition('keyword', state.conditions.keyword)" placeholder="대표 값으로 검색">
          <span class="reset pointer" @click="toggleCondition('keyword')" v-if="$route.query.keyword">&times;</span>
          <button class="btn" @click="toggleCondition('keyword', state.conditions.keyword)">
            <i class="fa fa-search"></i>
          </button>
        </label>
        <select class="form-control" v-model="state.conditions.categoryId" @change="toggleCondition('categoryId', state.conditions.categoryId)" :disabled="!state.category.loaded">
          <option :value="null">신청서 양식 분류 선택</option>
          <option :value="f.id" v-for="(f, idx) in state.category.list" :key="idx">{{ f.title }}</option>
        </select>
        <select class="form-control" v-model="state.conditions.formId" @change="toggleCondition('formId', state.conditions.formId)" :disabled="!state.form.loaded" v-if="state.conditions.categoryId">
          <option :value="null">신청서 양식 선택</option>
          <option :value="f.id" v-for="(f, idx) in state.form.list" :key="idx">{{ f.title }}</option>
        </select>
        <select class="form-control" v-model="state.conditions.managerSeq" @change="toggleCondition('managerSeq', state.conditions.managerSeq)" :disabled="!state.manager.loaded">
          <option :value="null">담당자 선택</option>
          <option :value="m.memberSeq" v-for="(m, idx) in state.manager.list" :key="idx">{{ m.memberName }}</option>
        </select>
        <div class="dropdown filter">
          <button class="btn btn-default dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :disabled="!state.applications.loaded">필터</button>
          <div class="dropdown-menu menu" aria-labelledby="dropdownMenuButton" @click.stop>
            <div v-for="(c, idx) in conditions" :key="idx">
              <span class="title">{{ c.title }}</span>
              <div class="items">
                <div class="item dropdown-item" :class="{active: String(state.conditions[c.name]) === String(i.value)}" v-for="(i, idx2) in c.items" :key="idx2" @click="toggleCondition(c.name, i.value)">
                  <span>{{ i.title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a class="btn btn-default excel" :href="computedExcelUrl" target="_blank" rel="noopener noreferrer" :class="{disabled: !state.applications.loaded || !state.applications.list.length || state.applications.downloaded}"
           @click="clickExcelDownload()">
          <i class="fa fa-download"></i>
          <span>목록 엑셀 다운로드</span>
        </a>
      </div>
    </div>
    <div class="table-responsive" v-if="state.applications.list.length || !state.applications.loaded">
      <table class="table">
        <thead>
        <tr>
          <th>
            <span>양식 제목</span>
          </th>
          <th>
            <span>대표 값</span>
          </th>
          <th>
            <span>최종 제출</span>
          </th>
          <th>
            <span>저장 일시</span>
          </th>
          <th>
            <span>담당자</span>
          </th>
          <th>
            <span>상세 보기</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(a, idx) in state.applications.list" :key="idx" :class="{ canceled: a.canceled }">
          <td class="limit" :title="a.formTitle">
            <div>
              <span class="badge badge-secondary" v-if="a.canceled">취소됨</span>
              <span class="badge" :class="a.status" v-else>{{ getConditionText("status", a.status) }}</span>
              <span class="ellipsis">{{ a.formTitle }}</span>
            </div>
          </td>
          <td class="limit ellipsis">
            <span>{{ a.keyAnswer }}</span>
          </td>
          <td>
            <span>{{ a.finished ? "예" : "아니오" }}</span>
          </td>
          <td>
            <span>{{ a.saveDate }}</span>
          </td>
          <td>
            <span class="manager" v-if="a.managerName" :style="manager.getStyle(a.managerName)">{{ a.managerName }}</span>
          </td>
          <td>
            <a class="pointer-u color-purple" @click="view(a)">상세 보기</a>
          </td>
        </tr>
        </tbody>
      </table>
      <PaginationJpa :pageable="state.applications.pageable" :total="state.applications.total" :change="load" v-if="state.applications.total" className="btn-primary"/>
    </div>
    <NoData v-else/>
  </div>
</template>

<script>
import {computed, defineComponent, reactive, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Wait from "@/components/Wait";
import http from "@/scripts/http";
import store from "@/scripts/store";
import NoData from "@/components/NoData";
import router from "@/scripts/router";
import lib from "@/scripts/lib";
import PaginationJpa from "@/components/PaginationJpa";
import formApplication from "../../../scripts/formApplication";
import manager from "@/scripts/manager";

function Component(initialize) {
  this.name = "pageAdminFormApplications";
  this.initialize = initialize;
}

export default defineComponent({
  components: {PaginationJpa, NoData, Wait},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      init();
    });

    const state = reactive({
      applications: {
        loaded: false,
        downloaded: false,
        list: [],
        pageable: {
          page: 0,
          size: 0,
        },
        total: 0,
        args: {
          page: 0,
          size: 50,
          sort: "saveDate,desc",
        },
      },
      conditions: {
        finished: null,
        canceled: null,
        status: null,
        categoryId: null,
        formId: null,
        managerSeq: "",
        keyword: "",
      },
      category: {
        loaded: false,
        list: [],
      },
      manager: {
        loaded: false,
        list: [],
      },
      form: {
        loaded: false,
        list: [],
        args: {
          categoryId: 0,
          page: 0,
          size: 50
        },
      },
    });

    const conditions = formApplication.conditions;

    const computedExcelUrl = computed(() => {
      const formId = computedConditions.value.find(c => c.name === "formId")?.value;

      if (!formId) {
        return null;
      }

      const urlArr = [`/download/admin/forms/${formId}/applications/excel`];
      const suffix = [];
      const categoryId = computedConditions.value.find(c => c.name === "categoryId")?.value;
      const finished = computedConditions.value.find(c => c.name === "finished")?.value;
      const canceled = computedConditions.value.find(c => c.name === "canceled")?.value;
      const status = computedConditions.value.find(c => c.name === "status")?.value;

      categoryId && suffix.push(`categoryId=${categoryId}`);
      finished && suffix.push(`finished=${finished}`);
      canceled && suffix.push(`canceled=${canceled}`);
      status && suffix.push(`status=${status}`);
      suffix.length && urlArr.push(`?${suffix.join("&")}`);

      return urlArr.join("");
    });

    const computedConditions = computed(() => {
      const result = [];
      const query = lib.getRenewed(router.app.$route.query);

      Object.keys(query).forEach((key) => {
        if (![...conditions.map(c => c.name), "formId", "categoryId", "managerSeq"].includes(key)) {
          return;
        }

        const title = key === "categoryId" ? "신청서 양식 분류" : key === "formId" ? "신청서 양식" : conditions.find(c => c.name === key)?.title;

        result.push({
          name: key,
          title,
          value: query[key],
          text: getConditionText(key, query[key], true)
        });
      });

      return result;
    });

    const load = (page, init) => {
      if (init) {
        for (let i in state.conditions) {
          state.conditions[i] = router.app.$route.query[i] || null;
        }
      }

      (page !== undefined && page !== null && page !== window.Number(router.app.$route.query.page)) && router.push({
        query: {
          ...router.app.$route.query,
          page,
        }
      });

      for (let i = 0; i < 10; i += 1) {
        state.applications.list.push({
          finished: false,
          canceled: false,
          formTitle: "Please Wait a moment",
          keyAnswer: "wait a moment",
          status: null,
          saveDate: "0000-00-00 00:00:00"
        });
      }

      state.applications.total = 0;
      state.applications.args.page = window.Number(router.app.$route.query.page) || 0;
      state.applications.loaded = false;

      const url = state.conditions.formId ? `/api/admin/form/${state.conditions.formId}/applications` : "/api/admin/form/applications";
      const args = {...state.applications.args, ...state.conditions};
      delete args.formId;

      http.get(url, args).then(({data}) => {
        state.applications.loaded = true;
        state.applications.list = data.body.content;
        state.applications.pageable = data.body.pageable;
        state.applications.total = data.body.total;
      });
    };

    const clickExcelDownload = () => {
      if (!computedConditions.value.find(c => c.name === "formId")?.value) {
        return store.commit("setSwingMessage", "다운로드하실 신청서 양식을 선택해주세요.");
      }

      state.applications.downloaded = true;
    };

    const loadCategoryList = () => {
      state.category.loaded = false;
      http.get("/api/admin/form/categories").then(({data}) => {
        state.category.loaded = true;
        state.category.list = data.body.content;
        state.conditions.categoryId && loadFormList();
      });
    };

    const loadFormList = () => {
      state.form.args.page = 0;
      state.form.loaded = false;
      state.form.args.categoryId = state.conditions.categoryId;

      http.get("/api/admin/forms", state.form.args).then(({data}) => {
        state.form.loaded = true;
        state.form.list = data.body.content;
      });
    };

    const loadManagerList = () => {
      state.manager.loaded = false;
      const args = {
        memberAuth: "MEMBER_MID_ADMIN",
        memberType: "P",
      };

      http.get("/api/admin/members", args).then(({data}) => {
        state.manager.loaded = true;
        state.form.loaded = true;
        state.manager.list = data.body.content;
      });
    };

    const view = (application) => {
      store.commit("openModal", {
        name: "ApplicationDetail",
        params: {
          application,
        },
        callback: `${component.name}.load`
      });
    };

    const getConditionText = (name, value, withTitle) => {
      if (name === "categoryId") {
        for (let i in state.category.list) {
          if (state.category.list[i].id?.toString() === value) {
            return state.category.list[i]?.title;
          }
        }
      } else if (name === "formId") {
        for (let i in state.form.list) {
          if (state.form.list[i].id?.toString() === value) {
            return state.form.list[i]?.title;
          }
        }
      } else if (name === "managerSeq") {
        for (let i in state.manager.list) {
          if (state.manager.list[i].memberSeq?.toString() === value) {
            return state.manager.list[i]?.memberName;
          }
        }
      } else {
        const condition = conditions.find(i => i.name === name?.toString());
        const items = condition?.items;

        for (let j in items) {
          if (items[j]?.value?.toString() === value?.toString()) {
            const arr = [];
            withTitle && arr.push(condition.title);
            arr.push(items[j]?.title);
            return arr.join(": ");
          }
        }
      }
    };

    const remove = (name) => {
      const query = lib.getRenewed(router.app.$route.query);
      delete query[name];
      query.page = "0";

      if (name === "categoryId") {
        delete query.formId;
      }

      router.push({query});
    };

    const toggleCondition = (name, value) => {
      if (computedConditions.value.some(c => c.name === name && (c.value?.toString() === value?.toString() || value === null))) {
        return remove(name);
      }

      const query = lib.getRenewed(router.app.$route.query);

      if (name === "categoryId") {
        delete query.formId;
      }

      if (query[name] === value && query.page === "0") {
        return init();
      }

      query[name] = value;
      query.page = "0";
      router.push({query});
    };

    watch(() => {
      return {
        finished: router.app.$route.query.finished,
        canceled: router.app.$route.query.canceled,
        status: router.app.$route.query.status,
        categoryId: router.app.$route.query.categoryId,
        formId: router.app.$route.query.formId,
        managerSeq: router.app.$route.query.managerSeq,
        page: router.app.$route.query.page,
        keyword: router.app.$route.query.keyword
      };
    }, (next, prev) => {
      if (JSON.stringify(next) === JSON.stringify(prev)) {
        return;
      }

      load(null, true);
      next.categoryId && next.categoryId !== prev.categoryId && loadFormList();
      state.applications.downloaded = false;
    });

    const init = () => {
      loadCategoryList();
      loadManagerList();
      load(null, true);
    };

    return {component, state, manager, conditions, computedExcelUrl, computedConditions, clickExcelDownload, remove, view, load, toggleCondition, getConditionText};
  }
});
</script>

<style lang="scss" scoped>
.admin-form-forms {
  position: relative;
  padding-bottom: $px25;

  .top {
    display: flex;
    gap: $px8;
    justify-content: space-between;
    align-items: flex-start;

    .left {
      display: flex;
      flex-direction: column;
      gap: $px8;

      .conditions {
        margin: $px-5 0 0 $px-4;
        display: flex;
        flex-wrap: wrap;
        gap: $px5;

        .condition {
          display: flex;
          border: $px1 solid $colorPurple;
          border-radius: $px100;
          color: $colorPurple;
          font-size: $px12;
          padding: $px4 $px10;
          margin: $px4 0 0 0;
          gap: $px5;

          .text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: $px200;
          }

          .remove {
            cursor: pointer;
          }
        }
      }
    }

    .right {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: $px8;

      .btn, select, .form-control {
        font-size: $px12;
        height: $px43;
        width: auto;
        padding-left: $px15;
        //padding-right: $px15;
        margin: 0;
      }

      .search {
        border: $px1 solid $colorBorder;
        border-radius: $px4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: border 0.18s;
        height: $px43;
        position: relative;
        z-index: 1;
        margin: 0;

        .form-control {
          height: $px41;
          font-size: $px12;
          border: 0;
        }

        .reset {
          padding: $px12;
        }

        .btn {
          font-size: $px12;
          border: 0;
          background: $colorBackground;
          height: $px41;
        }

        &:focus-within {
          border: $px1 solid $colorPurple;
        }
      }

      select {
        max-width: $px200;
      }

      .dropdown {
        position: relative;

        .btn {
          > div {
            display: inline-block;

            .condition {
              color: $colorPurple;
            }

            &:not(:last-child) {
              padding-right: $px4;
            }
          }

          &:after {
            margin-left: $px7;
          }
        }

        .dropdown-menu {
          width: $px250;
          position: absolute;
          padding: $px15;
          box-shadow: none;
          border: $px1 solid $colorBorder;
          margin-top: $px3;
          z-index: 10;

          > div {
            white-space: break-spaces;

            .title {
              font-size: $px12;
              font-weight: 500;
              display: block;
              white-space: nowrap;
              margin-bottom: $px3;
            }

            .items {
              margin-left: $px-5;

              .dropdown-item {
                border: $px1 solid $colorBorder;
                border-radius: $px15;
                cursor: pointer;
                display: inline-block;
                font-size: $px11;
                margin: $px5 0 0 $px5;
                padding: $px8 $px14;
                text-align: center;
                transition: background-color 0.18s;
                width: initial;

                > span {
                  white-space: nowrap;
                }

                &.active {
                  border-color: $colorPurple;
                  color: $colorPurple;
                }
              }
            }

            &:not(:last-child) {
              margin-bottom: $px20;
            }
          }
        }
      }

      .btn.excel {
        i {
          margin-right: $px7;
        }
      }
    }
  }

  .table-responsive {
    margin-top: $px16;

    table tr {
      th, td {
        padding-left: $px20;
        padding-right: $px20;
      }

      th {
        background: $colorBackground;
        border-bottom: 0;
      }

      td {
        > div {
          position: relative;
          display: flex;
          align-items: center;
          gap: $px7;

          > span {
            &.badge {
              display: inline-block;
              color: #fff;
              background: $colorSecondary;

              &.judge {
                background: $colorPurple;
              }

              &.pass {
                background: $colorPoint;
              }

              &.denied {
                background: $colorDanger;
              }
            }
          }
        }

        .manager {
          font-size: $px13;
          font-weight: bold;
        }

        &.limit {
          max-width: $px300;
        }
      }

      &.canceled td {
        opacity: 0.5;
      }
    }

    .pagination {
      margin-top: $px25;
    }
  }

  .no-data {
    padding: $px50 0;
  }

  &.skeleton {
    .top {
      select, button {
        @include skeleton;
      }
    }

    .above {
      .total,
      .conditions .condition {
        @include skeleton;
      }
    }

    .table-responsive {
      table tr {
        th, td {
          span, a {
            @include skeleton;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .top {
      flex-direction: column-reverse;

      .left {
        flex-direction: column;
        gap: $px10;

        .conditions .condition {
          font-size: $px10;
        }
      }

      .right {
        justify-content: flex-start;

        > select, > div, .btn {
          font-size: $px10;
        }

        > select {
          width: 50%;
        }
      }
    }
  }
}
</style>